import { useState } from "react";

export function Form() {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loading, setLoading] = useState(false)

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)

        handleUploadFiles(chosenFiles);
    }
    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        console.log(uploaded, uploadedFiles);
        files.forEach(file => uploaded.push(file))

        setUploadedFiles(uploaded);
    }
    const deleteUploadedFile = (indexToDelete) => {
        console.log('deleted');
        const newUploadedFiles = uploadedFiles.filter((file, index) => index !== indexToDelete)
        setUploadedFiles(newUploadedFiles);
        console.log('new useState is', uploadedFiles);
    }

    const handleSubmit = (event) => {
        setLoading(true)
    };


    return (
        <div id="form" className="container pt-4 pb-5">

            <h3 className="text-center pb-3 title">COMPILA IL FORM PER RICEVERE UNA VALUTAZIONE GRATUITA</h3>

            {loading && <div className="submit-loading d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            <form onSubmit={handleSubmit} name="carData" method="post" encType='multipart/form-data' className="row px-md-5 pt-1 ">
                <input type="hidden" name="form-name" value="carData" />
                <div className="col-md-6 mb-3">
                    <input name="marca" type="text" placeholder="Marca Veicolo" className="input-text" required />
                </div>
                <div className="col-md-6 mb-3">
                    <input name="modello" type="text" placeholder="Modello" className="input-text" required />
                </div>
                <div className="col-md-6 mb-3">
                    <input name="anno" type="text" placeholder="Anno Immatricolazione" className="input-text" required />
                </div>
                <div className="col-md-6 mb-3">
                    <input name="nome" type="text" placeholder="Nome Cognome" className="input-text" required />
                </div>
                <div className="col-md-6 mb-3">
                    <input name="email" type="email" placeholder="Email" className="input-text" required />
                </div>
                <div className="col-md-6 mb-3">
                    <input name="telefono" type="text" placeholder="Telefono" className="input-text" required />
                </div>
                <div className="col-12">
                    <div className={uploadedFiles.length < 1 ? 'mb-1' : 'hidden mb-1'}>
                        {/* <input name="file" type="file" id="upload" accept="image/*" multiple hidden onChange={handleFileEvent}></input> */}

                        <input name="file0" accept="image/*" type="file" id="upload0" hidden onChange={handleFileEvent}></input>
                        <label htmlFor="upload0" className="upload-label">
                            <span className="me-2">Clicca Per Caricare Una Foto</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                </svg>
                            </span>
                        </label>

                    </div>

                    <div>
                        {Array.from({ length: 4 }, (_, index) => (
                            <div key={index} className={uploadedFiles.length === index + 1 ? 'mb-1' : 'hidden mb-1'}>

                                <input name={'file' + (index + 1)} accept="image/*" type="file" id={'upload' + (index + 1)} hidden onChange={handleFileEvent}></input>
                                <label htmlFor={'upload' + (index + 1)} className='upload-label'>
                                    <span className="me-2">Clicca Per Caricare Una Foto</span>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                        </svg>
                                    </span>
                                </label>
                            </div>
                        ))}

                    </div>
                </div>
                <div className="file-wrapper">
                    <div className="uploaded-files-list">
                        {uploadedFiles.length > 0 && <strong>Puoi caricare max: 5 foto</strong>}

                        {uploadedFiles.map((file, index) => (
                            <div key={index} className="uploaded-file d-flex">
                                <div className="uploaded-file_text">{file.name}</div>

                                <span className="uploaded-file_delete-icon ms-2" onClick={() => deleteUploadedFile(index)} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                    </svg>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className={uploadedFiles.length < 1 ? 'mb-1' : 'hidden mb-1'}>

                        <input name="file1" accept="image/*" type="file" id="upload1" hidden onChange={handleFileEvent}></input>
                        <label htmlFor="upload1" className="upload-label">
                            <span className="me-2">Clicca Per Caricare Una Foto...</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                </svg>
                            </span>
                        </label>

                    </div>
                    
                    <div className={uploadedFiles.length === 1 ? 'mb-1' : 'hidden mb-1'}>

                        <input name="file2" accept="image/*" type="file" id="upload2" hidden onChange={handleFileEvent}></input>
                        <label htmlFor="upload2" className="upload-label">
                            <span className="me-2">Clicca Per Caricare Foto</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                </svg>
                            </span>
                        </label>

                    </div>
                   
                    <div className={uploadedFiles.length === 2 ? 'mb-1' : 'hidden mb-1'}>

                        <input name="file3" accept="image/*" type="file" id="upload3" hidden onChange={handleFileEvent}></input>
                        <label htmlFor="upload3" className="upload-label">
                            <span className="me-2">Clicca Per Caricare Foto</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                </svg>
                            </span>
                        </label>

                    </div>
                   
                    <div className={uploadedFiles.length === 3 ? 'mb-1' : 'hidden mb-1'}>

                        <input name="file4" accept="image/*" type="file" id="upload4" hidden onChange={handleFileEvent}></input>
                        <label htmlFor="upload4" className="upload-label">
                            <span className="me-2">Clicca Per Caricare Foto</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                </svg>
                            </span>
                        </label>

                    </div>
                    
                    <div className={uploadedFiles.length === 4 ? 'mb-1' : 'hidden mb-1'}>
                        <input name="file5" accept="image/*" type="file" id="upload5" hidden onChange={handleFileEvent}></input>
                        <label htmlFor="upload5" className="upload-label">
                            <span className="me-2">Clicca Per Caricare Foto</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                </svg>
                            </span>
                        </label>

                    </div>

                    <div className="file-wrapper">
                        <div className="uploaded-files-list">
                            {uploadedFiles.length > 0 && <strong>Dimensione max del file: 8 MB</strong>}

                            {uploadedFiles.map((file, index) => (
                                <div key={index} className="uploaded-file d-flex">
                                    <div className="uploaded-file_text">{file.name}</div>

                                    <span className="uploaded-file_delete-icon ms-2" onClick={() => deleteUploadedFile(index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                        </svg>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <div className="my-3" > <textarea name="messaggio" placeholder="Messaggio" /></div>


                <div className="col-12 text-center">
                    <button className="btn btn-danger btn-lg">INVIA MESSAGGIO</button>
                </div>

            </form>
        </div>
    )
}