export function WhyChooseUs() {

    return (
        <div id="whychooseus" >
            <div className="container py-5 px-sm-3 px-md-1">

                <div className="row " >
                    <div className="col-md-6 pb-3">
                        <h1 className="title">PERCHE SCEGLIERCI?</h1>
                    </div>
                    <div className="col-md-6 ">

                        <div className="about-box d-flex pb-3">
                            <span className='me-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#e7505a" className="bi bi-diamond-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z" />
                                </svg>
                            </span>
                            <div>
                                <h3 className='sub-title'>Facilità</h3>
                                <p className="text">
                                    Vendere la tua auto può essere complicato e pieno di inconvenienti.                                </p>
                            </div>
                        </div>

                        <div className="about-box d-flex pb-3">
                            <span className='me-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#e7505a" className="bi bi-diamond-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z" />
                                </svg>
                            </span>
                            <div>
                                <h3 className='sub-title'>Velocità</h3>
                                <p className="text">
                                    I nostri professionisti si impegnano nel fornire il servizio più rapido.                                </p>
                            </div>
                        </div>

                        <div className="about-box d-flex pb-3">
                            <span className='me-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#e7505a" className="bi bi-diamond-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435z" />
                                </svg>
                            </span>
                            <div>
                                <h3 className='sub-title'>Sicurezza</h3>
                                <p className="text">
                                    Kris Auto srl è progettato per essere sicuro, rapido e conveniente per te.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-5" >
                    <div className="col-md-6 text-md-start title text-center">
                        <h3>Richiedi una valutazione gratuita.</h3>
                    </div>
                    <div className="col-md-6 text-md-end text-center">
                        <a type='button' href='#form' className="btn btn-danger btn-lg">RICHIEDI VALUTAZIONE</a>
                    </div>
                </div>
            </div>
        </div>
    )
}