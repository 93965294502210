import Slider from "react-slick";
import { useState, useEffect } from "react";
import "../../sass/slick.scss";
import "../../sass/slick-theme.scss";
import image1 from "../../assets/gallery/1.jpeg";
import image2 from "../../assets/gallery/2.jpeg";
import image3 from "../../assets/gallery/3.jpeg";
import image4 from "../../assets/gallery/4.jpeg";
import image5 from "../../assets/gallery/5.jpeg";
import image6 from "../../assets/gallery/6.jpeg";
import image7 from "../../assets/gallery/7.jpeg";
import image8 from "../../assets/gallery/8.jpeg";
import image9 from "../../assets/gallery/9.jpeg";
import image10 from "../../assets/gallery/10.jpeg";
import image11 from "../../assets/gallery/11.jpeg";
import image12 from "../../assets/gallery/12.jpeg";
import image13 from "../../assets/gallery/13.jpeg";
import image14 from "../../assets/gallery/14.jpeg";

export function SliderGallery() {

	const [images, setImages] = useState([]);
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);

	useEffect(() => {
		console.log('Componente montato');
		setImages([image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14])

		// Funzione di cleanup
		return () => {
			console.log('Componente smontato');
		};
	}, [])

	const next = () => {
		nav1.slickNext();
	};

	const previous = () => {
		nav1.slickPrev();
	};

	const settings1 = {
		infinite: true,
		speed: 500,
		arrows: false,
	}
	const settings2 = {
		arrows: false
	}

	return (

		<div>

			<Slider {...settings1} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
				{images.map((image, index) => (
					<div key={`main ${index}`} className="col px-2">

						<div className="container-image" >
							{/* prev */}
							<div className="btn btn-danger prev" onClick={previous}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
									<path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
								</svg>
							</div>

							<img className="w-100" src={image} alt={`Immagine ${index + 1}`} />

							{/* next */}
							<div className="btn btn-danger next" onClick={next}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
									<path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
								</svg>
							</div>
						</div>
					</div>
				))}
			</Slider>

			<Slider
				{...settings2}
				asNavFor={nav1}
				ref={(slider2) => setNav2(slider2)}
				slidesToShow={3}
				swipeToSlide={true}
				focusOnSelect={true}
			>
				{images.map((image, index) => (
					<div key={`footer ${index}`} className="col">
						<img className="w-100 px-2" src={image} alt={`Immagine ${index + 1}`} />
					</div>
				))}
			</Slider>
		</div>
	)
}