import { SliderGallery } from "./Sliders/SliderGallery";

export function Gallery() {
    return <div id="gallery" className="slider py-5 ">
        <div className="title px-3 b-2 text-center ">
            {/* <h1 className="">
                GALLERIA
            </h1> */}
            <h2 className="title mb-4">
                {/* Scorri tra le immagini per visionare i nostri acquisti */}
                SCORRI TRA LE IMMAGINI PER VISIONARE I NOSTRI ACQUISTI
            </h2>

        </div>

        <div className="container px-lg-4 px-md-3 px-2">
            <SliderGallery></SliderGallery>
        </div>
    </div>
}