import { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

export function Statistics() {

    const [counterOn, setCounterOn] = useState(false)
    return <div id="statistics" className=" py-5" style={{ background: 'white', borderTop: '1px solid #f0f0f0', borderBottom: '1px solid #f0f0f0' }}>
        <div className='container  text-center'>
        <h3 className="title">Alcune delle nostre statistiche che devi conoscere: </h3>

            <div className="row">
                <div className="col-md-4 my-4">
                    <ScrollTrigger onEnter={() => setCounterOn(true)} >
                        {/* onExit={() => setCounterOn(false)} */}
                        <h1 className="title" style={{ color: '#e7505a' }}>
                            <strong>+{counterOn && <CountUp start={0} end={3500} duration={3} separator={'.'} />}</strong>
                        </h1>
                        <h5 className="text">Totale auto acquistate</h5>
                    </ScrollTrigger>

                </div>

                <div className="col-md-4 my-4">
                    <ScrollTrigger onEnter={() => setCounterOn(true)} >
                        {/* onExit={() => setCounterOn(false)} */}
                        <h1 className="title" style={{ color: '#e7505a' }}>
                            <strong>+{counterOn && <CountUp start={0} end={15000} duration={3} separator={'.'} />}</strong>
                        </h1>
                        <h5 className="text">Tempo risparmiato dai nostri clienti</h5>
                    </ScrollTrigger>
                </div>

                <div className="col-md-4 my-4">
                    <ScrollTrigger onEnter={() => setCounterOn(true)} >
                        {/* onExit={() => setCounterOn(false)} */}
                        <h1 className="title" style={{ color: '#e7505a' }}>
                            <strong>+{counterOn && <CountUp start={0} end={7000000} duration={3} separator={'.'} />}</strong>
                        </h1>

                        <h5 className="text">Valore auto acquistate</h5>
                    </ScrollTrigger>
                </div>

            </div>
        </div>
    </div >
}