import { useState } from "react"

export function Header() {
    const [activeLink, setActiveLink] = useState('home');

    const onUpdateActiveLink = (value) => {
        setActiveLink(value)
    }

    return (
        // <nav id="header" className="navbar navbar-expand-sm fixed-top">

        //     <div className="container-fluid">
        //         <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
        //             <span className="navbar-toggler-icon"></span>
        //         </button>
        //         <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        //             <div className="offcanvas-header">
        //                 <h5 className="offcanvas-title" id="offcanvasNavbarLabel">KRIS AUTO SRL</h5>
        //                 <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        //             </div>
        //             <div className="offcanvas-body">
        //                 <ul className="navbar-nav m-auto mb-2 mb-lg-0">
        //                     <li className="nav-item text-center">
        //                         <a className={activeLink === 'home' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('home')} aria-current="page" href="#home">HOME</a>
        //                     </li>
        //                     <li className="nav-item text-center">
        //                         <a className={activeLink === 'about' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('about')} href="#about">ABOUT</a>
        //                     </li>

        //                     <li className="nav-item text-center">
        //                         <a className={activeLink === 'services' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('services')} href="#services">SERVICES</a>
        //                     </li>

        //                     <li className="nav-item text-center">
        //                         <a className={activeLink === 'contact' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('contact')} href="#contact">CONTACT</a>
        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        // </nav>

        <nav id="header" className="navbar navbar-expand ">
            <div className="container-fluid d-flex justify-content-center">
                <ul className="navbar-nav ">
                    <li className="nav-item">
                        <a className={activeLink === 'home' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('home')} aria-current="page" href="#home">HOME</a>
                    </li>

                    <li className="nav-item">
                        <a className={activeLink === 'services' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('services')} href="#services">SERVIZI</a>
                    </li>

                    <li className="nav-item">
                        <a className={activeLink === 'statistics' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('statistics')} href="#statistics">SU DI NOI</a>
                    </li>

                    <li className="nav-item">
                        <a className={activeLink === 'contact' ? 'active nav-link' : 'nav-link'} onClick={() => onUpdateActiveLink('contact')} href="#contact">CONTATTACI</a>
                    </li>
                </ul>
            </div>
        </nav>



    )
}