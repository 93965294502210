import { Arrow } from './component/Arrow';
import { Contactt } from './component/Contactt';
import { Footer } from './component/Footer';
import { Form } from './component/Form';
import { Home } from './component/Home';
// import { Reviews } from './component/Reviews';
import { Servicess } from './component/Servicess';
import { Statistics } from './component/Statistics';
import { Whatsapp } from './component/Whatsapp';
import { WhyChooseUs } from './component/WhyChooseUs';
import { Gallery } from "./component/Gallery";

function App() {
  return (
    <div className='App'>
      <Whatsapp></Whatsapp>
      <Arrow></Arrow>
      <div>
        <Home></Home>
        <Contactt></Contactt>
        <Form></Form>
        <Servicess></Servicess>
        <WhyChooseUs></WhyChooseUs>
        <Statistics></Statistics>
        <Gallery></Gallery>
        {/* <Reviews></Reviews> */}
      </div>
        <Footer></Footer>
    </div>
  );
}

export default App;
